<template>
  <div>
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input v-model="queryInfo.query" placeholder="请输入内容"  >
            <el-button slot="append" icon="el-icon-search" @click="getTableData"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table  border stripe
        :data="tableData"
        style="width: 100%">
        <el-table-column fixed
          prop="company_ID"
          label="公司ID"
          width="150">
        </el-table-column>
        <el-table-column fixed
          prop="company_NAME"
          label="公司名称"
          width="260">
        </el-table-column>
        <el-table-column fixed
          prop="fiscal_YEAR"
          label="财报年份"
          width="150">
        </el-table-column>
        <el-table-column fixed
          prop="city_INVEST_NAME"
          label="是否城投"
          width="150">
        </el-table-column>
        <!-- <el-table-column fixed
          prop="gics_NAME"
          label="主体行业"
          width="150">
        </el-table-column> -->
        <el-table-column fixed
          prop="gics_NAME"
          label="财报行业"
          width="150">
        </el-table-column>
        <el-table-column fixed
          prop="sponse_NAME"
          label="任务发起人"
          width="150">
        </el-table-column>
        <el-table-column fixed
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" >开始审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
    </el-card>
    <el-dialog title="审核" :visible.sync="recordDialogVisible"  width="50%" >
       <el-form ref="checkData" :model="checkData" label-width="80px" :rules="rules" class="demo-ruleForm" >
        <el-form-item label="公司名称 :"  >
          <el-row>
            <el-col :span="11">
              {{checkData.company_NAME}}({{checkData.share_LISTED_NAME}})
            </el-col>
            <el-col :span="11">
             <span style="font-weight:bold;">上年城投信息</span>
           </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="企业性质 :"  >
          <el-col :span="11">
            {{checkData.org_TYPE_NAME === null ?'\xa0' : checkData.org_TYPE_NAME}}
          </el-col>
          <!-- <el-col :span="5" v-if="checkData.last_CITY_INVEST_NAME != '' & checkData.last_CITY_INVEST_NAME != null">
             <span>是否城投 :</span>
             <span class="myspan">{{checkData.last_CITY_INVEST_NAME}}</span>
           </el-col> -->
           <el-col :span="5" v-if="checkData.last_REGION_NAME != '' & checkData.last_REGION_NAME != null">
            <span>政府归属 :</span>
            <span class="myspan">{{checkData.last_REGION_NAME}}</span>
           </el-col>
        </el-form-item>
        <el-form-item label="财报年份 :"  >
          <el-col :span="11">
            {{checkData.fiscal_YEAR === null ?'\xa0' : checkData.fiscal_YEAR}}
          </el-col>
          <el-col :span="11" v-if="checkData.last_spt_index_name1 != ''">
              <span>{{checkData.last_spt_index_name1}} :</span>
              <span class="myspan">{{checkData.last_spt_level_name1}}</span>
           </el-col>
        </el-form-item>
        <!-- 行业显示的条件 本次gics_code与上年不同或者城投检查未检查  -->
        <!-- <div v-if="checkData.last_GICS_CODE != checkData.gics_CODE || checkData.check_CITY_INVEST != '1'"> -->
          <el-form-item label="上年行业 :" >
            <el-col :span="11">
              {{checkData.last_GICS_NAME === null ?'\xa0' : checkData.last_GICS_NAME}}
            </el-col>
            <el-col :span="11" v-if="checkData.last_spt_index_name2 != ''">
              <span>{{checkData.last_spt_index_name2}} :</span>
              <span class="myspan">{{checkData.last_spt_level_name2}}</span>
            </el-col>
          </el-form-item>
          <el-form-item label="财报行业 :" >
            <el-col :span="11">
              {{checkData.gics_NAME === null ?'\xa0' : checkData.gics_NAME}}
            </el-col>
            <el-col :span="11" v-if="checkData.last_spt_index_name3 != ''">
              <span>{{checkData.last_spt_index_name3}} :</span>
              <span class="myspan">{{checkData.last_spt_level_name3}}</span>
            </el-col>
          </el-form-item>
        <!-- </div> -->
        <el-form-item label="主体归属 :" >
          <el-col :span="11">
            {{checkData.company_REGION_NAME === null ?'\xa0' : checkData.company_REGION_NAME}}
          </el-col>
          <el-col :span="8" v-if="checkData.last_spt_index_name4 != ''">
            <span>{{checkData.last_spt_index_name4}} :</span>
            <span class="myspan">{{checkData.last_spt_level_name4}}</span>
          </el-col>
          <el-col :span="5" v-if="checkData.last_SPT_SCORE != '' & checkData.last_SPT_SCORE != null">
            <span>总分 :</span>
            <span class="myspan">{{checkData.last_SPT_SCORE}}</span>
           </el-col>
        </el-form-item>
        <div v-if="checkData.check_CITY_INVEST == '1'">
          <el-form-item label="是否城投 :" >
            <el-col :span="11">
              {{checkData.city_INVEST_NAME}}
            </el-col>
          </el-form-item>
          <div v-if="checkData.city_INVEST==1" >
            <el-form-item label="政府归属 :" >
              {{checkData.region_NAME}}
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item >
                <el-row >
                  <el-col :span="8">支持维度</el-col>
                  <el-col :span="7">支持程度</el-col>
                </el-row>
                <el-row v-for="(item,index) in checkData.taSptDetall" :key="index">
                  <el-col :span="8" v-html="item.support_INDEX_NAME"></el-col>
                  <el-col :span="7" v-html="item.support_LEVEL_NAME==null ? 'NR': item.support_LEVEL_NAME"></el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="总分 :">
                {{checkData.spt_SCORE}}
              </el-form-item>
          </div>
        </div>
        <el-form-item label="审核意见 :"  prop="process_OPINION">
          <el-input :autosize="{ minRows: 4}" type="textarea" v-model="checkData.process_OPINION"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="pass">审核通过</el-button>
          <el-button  @click="back('checkData')">退回</el-button>
        </el-form-item>
       </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'FlowTask',
  data () {
    return {
      recordDialogVisible: false,
      tableData: [],
      checkData: {
        company_ID: '',
        company_NAME: '',
        fiscal_YEAR: '',
        spt_SCORE: '',
        gics_NAME: '',
        city_INVEST_NAME: '',
        region_NAME: '',
        taSptDetall: [],
        process_OPINION: '',
        last_GICS_CODE: '',
        check_CITY_INVEST: '',
        gics_CODE: '',
        company_REGION_NAME: '',
        region: '',
        org_TYPE_NAME: '',
        share_LISTED_NAME: '',
        last_spt_level_name1: '',
        last_spt_level_name2: '',
        last_spt_level_name3: '',
        last_spt_level_name4: '',
        last_spt_index_name1: '',
        last_spt_index_name2: '',
        last_spt_index_name3: '',
        last_spt_index_name4: '',
        last_CITY_INVEST_NAME: '',
        last_REGION_NAME: '',
        last_SPT_SCORE: ''
      },
      queryInfo: {
        pagenum: 1,
        pagesize: 10
      },
      totle: 0,
      rules: {
        process_OPINION: [
          { required: false, message: '审核意见', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/flow', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    async  handleClick (row) {
      const { data: checkFlow } = await this.$http.get('check/flow/process', {
        params: { task_ID: row.task_ID }
      })
      if (checkFlow) {
        const { data: checkres } = await this.$http.get('query/flow/id', {
          params: { task_ID: row.task_ID }
        })
        this.checkData = checkres
        this.checkData.share_LISTED_NAME = checkres.share_LISTED_NAME
        this.checkData.last_CITY_INVEST_NAME = checkres.last_CITY_INVEST_NAME
        this.checkData.last_REGION_NAME = checkres.last_REGION_NAME
        this.checkData.last_SPT_SCORE = checkres.last_SPT_SCORE
        if (checkres.lastSptDetall != null && checkres.lastSptDetall.length > 0) {
          for (const item of checkres.lastSptDetall) {
            if (item.support_INDEX_ID === 10) {
              this.checkData.last_spt_level_name1 = item.support_LEVEL_NAME
              this.checkData.last_spt_index_name1 = item.support_INDEX_NAME
            } else if (item.support_INDEX_ID === 20) {
              this.checkData.last_spt_level_name2 = item.support_LEVEL_NAME
              this.checkData.last_spt_index_name2 = item.support_INDEX_NAME
            } else if (item.support_INDEX_ID === 30) {
              this.checkData.last_spt_level_name3 = item.support_LEVEL_NAME
              this.checkData.last_spt_index_name3 = item.support_INDEX_NAME
            } else if (item.support_INDEX_ID === 40) {
              this.checkData.last_spt_level_name4 = item.support_LEVEL_NAME
              this.checkData.last_spt_index_name4 = item.support_INDEX_NAME
            }
          }
        } else {
          this.checkData.last_spt_index_name1 = ''
          this.checkData.last_spt_index_name2 = ''
          this.checkData.last_spt_index_name3 = ''
          this.checkData.last_spt_index_name4 = ''
          this.checkData.last_spt_level_name1 = ''
          this.checkData.last_spt_level_name2 = ''
          this.checkData.last_spt_level_name3 = ''
          this.checkData.last_spt_level_name4 = ''
        }
        this.recordDialogVisible = true
      } else {
        this.$alert('此任务已被别人处理', '信息提示').then(() => {
          this.getTableData()
        }).catch(() => {
        })
      }
    },
    async pass () {
      const { data: checkFlow } = await this.$http.get('check/flow/process', {
        params: { task_ID: this.checkData.task_ID }
      })
      if (checkFlow) {
        await this.$http.post('flow/pass', {
          task_ID: this.checkData.task_ID, region: this.checkData.region, process_OPINION: this.checkData.process_OPINION, company_ID: this.checkData.company_ID, fiscal_YEAR: this.checkData.fiscal_YEAR, check_CITY_INVEST: this.checkData.check_CITY_INVEST, gics_CODE: this.checkData.gics_CODE
        })
        this.recordDialogVisible = false
      } else {
        this.$alert('此任务已被别人处理', '信息提示').then(() => {
          this.getTableData()
        }).catch(() => {
        })
      }
      this.getTableData()
    },
    async back (formName) {
      this.rules.process_OPINION[0].required = true
      let istrue
      // form验证
      this.$refs[formName].validate((valid) => {
        if (valid) {
          istrue = true
        } else {
          return false
        }
      })
      if (istrue) {
        const { data: checkFlow } = await this.$http.get('check/flow/process', {
          params: { task_ID: this.checkData.task_ID }
        })
        if (checkFlow) {
          await this.$http.post('flow/back', {
            task_ID: this.checkData.task_ID, process_OPINION: this.checkData.process_OPINION
          })
          this.recordDialogVisible = false
          this.getTableData()
        } else {
          this.$alert('此任务已被别人处理', '信息提示').then(() => {
            this.getTableData()
          }).catch(() => {
          })
        }
      }
    }
  },
  created () {
    this.getTableData()
  }
}
</script>

<style>
.el-dialog{
    display: flex;
    flex-direction: column;
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    /*height:600px;*/
    max-height:calc(100% - 30px);
    max-width:calc(100% - 30px);
}
.el-dialog .el-dialog__body{
    flex:1;
    overflow: auto;
}
.el-table__body tr.current-row>td {
    color: #fff;
    background-color: #a2a4a7!important;
}
.el-form-item {
  margin-top: -20px
}
  .myspan {
    padding-left: 12px;
    color:blue;
  }
</style>
